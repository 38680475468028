<template>
  <div class="homePage">
    <div
    >
      <HomepageBanner
        :homeBannerData="homeBannerData"
        :autoplayTimeout="10000"
      />
      <div class="mainText">
        <router-link to="/c/online-shopping">
          <h1 class="collectionTitle">
            VEGAN SUPERMARKET
          </h1>
        </router-link>
        <div>
          {{ mainText.text }}
        </div>
      </div>
      <USPBanner />
      <ListingSection
        sectionTitle="Top Deals"
        titleLink="/c/top-deals"
        collection="vk_deals"
        :userLogged="$store.getters.userLoggedIn"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <ListingSection
        sectionTitle="Top plant-based Products"
        titleLink=""
        collection="vk_homepage"
        :userLogged="$store.getters.userLoggedIn"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <ListingSection
        sectionTitle="New Arrivals"
        titleLink=""
        collection="vk_newarrivals"
        :userLogged="$store.getters.userLoggedIn"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <ListingSection
        sectionTitle="Vegan Gifts & Hampers"
        titleLink="/c/gifts"
        collection="vk_homepagegifts"
        :userLogged="$store.getters.userLoggedIn"
        :perPage="10"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <template>
        <div class="imageBannerGrid">
          <div
            v-for="image in imageBanners"
            :key="image.target"
          >
            <router-link
              :to="image.target"
              :aria-label="image.ariaLabel"
            >
              <v-lazy-image :src="getImgUrl(image.src)" :alt="image.ariaLabel" />
            </router-link>
          </div>
        </div>
      </template>
      <ListingSection
        sectionTitle="Vegan Ready Meals"
        titleLink="/c/ready-meals"
        collection="vk_readymeals"
        :userLogged="$store.getters.userLoggedIn"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <ListingSection
        sectionTitle="Vegan Essentials"
        titleLink="/c/vegan-essentials"
        collection="vk_homepageveganessentials"
        :userLogged="$store.getters.userLoggedIn"
        :perPage="10"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <ListingSection
        sectionTitle="Vegan Recipes"
        titleLink="/recipes"
        collection="vk_christmasrecipes"
        :ignoreVeganFlag="true"
        itemType="recipe"
        :userLogged="$store.getters.userLoggedIn"
        :sortByNewest="true"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
      <div class="textBannerGrid">
        <div
          v-for="banner in textBanners"
          :key="banner.title"
          class="bannerItem"
        >
          <h2>
            {{ banner.title }}
          </h2>
          <div v-html="banner.text"></div>
        </div>
      </div>
      <BrandLogoGrid sectionTitle="Buy From our market" :brandsArray="brandsAvailable" />
      <BrandLogoGrid sectionTitle="Brands Joining Soon" :brandsArray="brandsSoonAvailable" />
      <v-lazy-image
        :src="getImgUrl(footerImage)"
        alt="The Home of plant-based"
        style="width: 100%; padding: 50px 0 50px 0"
      />
      <div class="footerLinks">
        <div
          v-for="link in footerLinks"
          :key="link.linkText"
        >
         <a :href="link.target" target="_blank">{{ link.linkText }}</a>
         {{ link.text }}
        </div>
      </div>
    </div>
    <!-- <ItemList
      v-else
      itemType=""
      collectionName=""
      @addItemToCart="$emit('addItemToCart', $event)"
    /> -->
  </div>
</template>

<script>
// import ItemList from './ItemList.vue'
// import USPBanner from '@/components/UspBanner.vue'
import siteConstants from '../const.js'
import HomepageBanner from '@/components/HomepageBanner.vue'
import ListingSection from '@/components/ListingSection.vue'

export default {
  name: 'HomePage',
  components: {
    // ItemList
    HomepageBanner,
    ListingSection,
    BrandLogoGrid: () => import('@/components/BrandLogoGrid.vue'),
    USPBanner: () => import('@/components/UspBanner.vue')
  },
  props: {},
  metaInfo () {
    const jsonld = {
      '@context': 'http://schema.org/',
      name: 'Home Page',
      type: 'WebPage',
      description: this.mainText.text
    }
    return {
      title: 'Vegan Supermarket',
      titleTemplate: `%s - ${siteConstants.siteName}`,
      link: [
        { rel: 'canonical', href: `${siteConstants.siteUrl}` }
      ],
      meta: [
        { name: 'description', content: this.mainText.text },
        { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' }
      ],
      script: [{
        type: 'application/ld+json',
        json: jsonld
      }]
    }
  },
  data: function () {
    return {
      siteConstants,
      footerImage: 'homepageBottomBanner.webp',
      homeBannerData: [
        // {
        //   src: 'easterBannerVK.jpg',
        //   title: 'Browse our range of Vegan Easter sweets and chocolate treats',
        //   description: 'Vegan cream eggs, chocolate bunnies & more!',
        //   backgroundColor: '#75b3c7',
        //   buttonText: 'Shop now',
        //   buttonTarget: '/c/easter',
        //   titleTextColor: '#fff',
        //   descriptionTextColor: '#000',
        //   titleTextBackground: '#000'
        // },
        {
          src: 'proteinBanner.jpg',
          title: 'Getting enough protein on a vegan diet?',
          description: 'Discover healthy plant-based protein sources.',
          backgroundColor: '#5CA8B8',
          buttonText: 'Shop Now',
          buttonTarget: '/c/tofu-tempeh-seitan',
          titleTextColor: '#fff',
          descriptionTextColor: '#000',
          titleTextBackground: '#000'
        },
        {
          src: 'indianBanner.jpg',
          title: 'Everything you need to make a vegan Indian feast',
          description: '',
          backgroundColor: '#535466',
          buttonText: 'Shop Now',
          buttonTarget: '/c/indian',
          titleTextColor: '#fff',
          descriptionTextColor: '#000',
          titleTextBackground: '#000'
        },
        {
          src: 'topDealsBanner.jpg',
          title: 'Save more!',
          description: 'Check our Top Deals with top vegan products at lower prices',
          backgroundColor: '#FED8D9',
          buttonText: 'Shop now',
          buttonTarget: '/c/top-deals',
          titleTextColor: '#fff',
          descriptionTextColor: '#000',
          titleTextBackground: '#000'
        }
      ],
      footerLinks: [
        // {
        //   linkText: 'Whole Food',
        //   target: 'https://wholefoodearth.com',
        //   text: 'Earth ©'
        // }
      ],
      mainText: {
        // title: 'Plant-based Market', // Unused since title needs different styling
        text: `Welcome to the ${siteConstants.siteName} Marketplace! Whether you follow a vegan lifestyle, a flexitarian or plant-based diet, or just dabble in new culinary experiences, we are here to make your plant-based journey easy, tasty and diverse. We give you access to many vegan brands, manufacturers, products and offers.`
      },
      textBanners: [
        {
          title: 'Plant-based Food & Lifestyle',
          text: 'Vegan means kindness to the world, animals, and humans, respecting nature and our planet. Doing good for the environment and doing good for humanity go hand in hand. We offer products that are better for human health, the environment, climate change and animals. Shopping for plant-based food is an easy and accessible way to reduce your carbon footprint and tackle climate change. We will help you live more consciously with no effort.'
        },
        {
          title: 'Marketplace For Eco-conscious Brands',
          text: 'We help you discover ethical brands and shops that put the planet first. We support brands that are honest, transparent and bold, and committing to a better world. We help you discover the best vegan brands and buy your favourite plant-based products directly from manufacturers, small shops and family businesses that care about the world and future.'
        },
        {
          title: 'Easy Shopping & Unique Products',
          text: 'From daily vegan essentials to vegan artisan foods and premium products, our marketplace will offer you a large selection of dairy-free and meat-free products. You will find everything you need to stock up your kitchen pantry: from vegan staples such as plant-based milk, dairy-free mayonnaise, legumes, grains, tofu, tempeh, nutritional yeast, egg replacers, plant-based proteins, vegan chocolate, snacks and much more!'
        },
        {
          title: 'Vegan-friendly Travelling & Dining',
          text: 'Explore plant-based and vegan dining and sustainable holiday options. We will help you find healthy and delicious dishes at vegan-friendly restaurants and discover vegan-friendly and eco-conscious holiday destinations around the world.'
        }
      ],
      imageBanners: [
        {
          target: '/o/happy-beans',
          ariaLabel: 'Happy Beans merchant',
          src: 'happybeansBanner.png'
        },
        {
          target: '/c/plant-based-cheese',
          ariaLabel: 'Plant based cheese',
          src: 'cheeseBanner.png'
        }
      ],
      brandsAvailable: [
        {
          name: 'Biona',
          src: 'brand-logos/biona.jpg',
          slug: 'biona-organisation'
        },
        {
          name: 'Alpro',
          src: 'brand-logos/alpro.png',
          slug: 'alpro-organisation'
        },
        {
          name: 'Mr Organic',
          src: 'brand-logos/mrOrganic.jpg',
          slug: 'mr-organic'
        },
        {
          name: 'Love Raw',
          src: 'brand-logos/loveRaw.png',
          slug: 'love-raw'
        },
        {
          name: 'Wholefood Earth',
          src: 'brand-logos/wholefoodearth.png',
          slug: 'wholefood-earth'
        },
        {
          name: 'Mari Gold',
          src: 'brand-logos/marigold.jpg',
          slug: 'marigold'
        },
        {
          name: 'Moo Free',
          src: 'brand-logos/mooFree.webp',
          slug: 'moo-free'
        },
        {
          name: 'Amisa',
          src: 'brand-logos/amisa.png',
          slug: 'amisa-organisation'
        },
        {
          name: 'Vego Chocolate',
          src: 'brand-logos/vego.png',
          slug: 'vego-organisation'
        },
        {
          name: 'Oatly',
          src: 'brand-logos/oatly.png',
          slug: 'oatly-9ks2'
        },
        {
          name: 'Clearspring',
          src: 'brand-logos/clearspring.jpg',
          slug: 'clearspring'
        },
        {
          name: 'Hippeas',
          src: 'brand-logos/hippeas.png',
          slug: 'hippeas-organisation'
        },
        {
          name: 'Taifun',
          src: 'brand-logos/taifun.png',
          slug: 'taifun-organisation'
        },
        {
          name: 'Beyond Meat',
          src: 'brand-logos/beyondmeat.png',
          slug: 'beyond-meat'
        },
        {
          name: 'Omni',
          src: 'brand-logos/omni.png',
          slug: 'omni-organisation'
        },
        {
          name: 'This',
          src: 'brand-logos/this.webp',
          slug: 'this-organisation'
        },
        {
          name: 'Moving Mountains',
          src: 'brand-logos/movingmountains.png',
          slug: 'moving-mountains'
        },
        {
          name: 'Juicy Marbles',
          src: 'brand-logos/juicymarbles.png',
          slug: 'juicy-marbles'
        }
      ],
      brandsSoonAvailable: [
        {
          name: 'Meatless Farm',
          src: 'brand-logos/meatlessfarm.png'
        },
        {
          name: 'Oumph',
          src: 'brand-logos/oumph.webp'
        },
        {
          name: 'Impossible',
          src: 'brand-logos/impossible.png'
        },
        {
          name: 'Just',
          src: 'brand-logos/just.png'
        },
        {
          name: 'Vivera',
          src: 'brand-logos/vivera.png'
        },
        {
          name: 'Heura',
          src: 'brand-logos/heura.png'
        }
      ]
    }
  },
  mounted () {
    // Get routeparams
    const params = this.$route.query
    if (params.purchaseId) {
      this.$emit('openPurchases', params.purchaseId)
    } else if (params.openContacts) {
      this.$emit('openUserContacts')
    }
  },
  methods: {
    getImgUrl (imageSrc) {
      return require('../assets/' + imageSrc)
    }
  }
}
</script>

<style scoped lang="stylus">
.homePage {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;

  .mainText {
    padding: 0px 0px 30px 0px;
    color:black

    .bebasNeue {
      font-family: 'Bebas Neue', sans-serif;
    }
  }

  .listingContainer {
    min-height: 168px;
  }

  .imageBannerGrid {
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr))
    }

    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: 15px;
    margin: 0 0 25px 0;

    img {
      border-radius: 0.375rem;
      width: 100%;
      height: 100%;
    }
  }

  .textBannerGrid {
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr))
      grid-gap: 0px;
    }

    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: 20px;
    margin: 0 0 25px 0;
    text-align: left;

    .bannerItem {
      h2 {
        padding: 0 0 10px 0;
        font-family: 'Bebas Neue', sans-serif;
      }

      padding: 20px 0 10px 0;
    }
  }

  .collectionTitle {
    margin-top: 30px;
    font-family: 'Bebas Neue', sans-serif;
  }

  .footerLinks {
    padding-bottom: 15px;
  }
}
</style>

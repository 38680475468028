// import axios from 'axios'
import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '@/views/HomePage.vue'
import ViewContainer from '@/views/ViewContainer.vue'
const ItemList = () => import(/* webpackChunkName: "itemLink" */'@/views/ItemList.vue')
const ItemPage = () => import(/* webpackChunkName: "itemPage" */'@/views/ItemPage.vue')
const RecipePage = () => import(/* webpackChunkName: "recipe" */'@/views/RecipePage.vue')
const ItemArticle = () => import(/* webpackChunkName: "article" */'@/views/ItemArticle.vue')
const MobileAppAdvert = () => import(/* webpackChunkName: "mobileApp" */'@/views/MobileAppAdvert.vue')
const p404 = () => import(/* webpackChunkName: "notfound" */'@/views/404.vue')
const ContactPage = () => import(/* webpackChunkName: "contact" */'@/views/ContactPage.vue')
const ShippingPage = () => import(/* webpackChunkName: "shipping" */'@/views/ShippingPage.vue')
// const PlatformUpdates = () => import('@/views/PlatformUpdatesPage.vue')
const OrgPage = () => import(/* webpackChunkName: "organisation" */'@/views/OrgPage.vue')
const PrivacyPage = () => import(/* webpackChunkName: "privacy" */'@/views/PrivacyPage.vue')
const AddListingPage = () => import(/* webpackChunkName: "listing" */'@/views/AddListingPage.vue')
const menuCategories = () => import(/* webpackChunkName: "categories" */'@/menuCategories.js')
const LoginCode = () => import(/* webpackChunkName: "login" */'@/views/LoginCode.vue')
const AffiliatePage = () => import(/* webpackChunkName: "affiliate" */'@/views/AffiliatePage.vue')
const MerchantPage = () => import(/* webpackChunkName: "merchant" */'@/views/MerchantPage.vue')
const AboutPage = () => import(/* webpackChunkName: "about" */'@/views/AboutPage.vue')
const TermsConditions = () => import(/* webpackChunkName: "t&c" */'@/views/TermsConditionsPage.vue')
const UserCart = () => import(/* webpackChunkName: "cart" */'@/views/UserCartPage.vue')
const Checkout = () => import(/* webpackChunkName: "checkout" */'@/views/CheckoutPage.vue')
const ThankYou = () => import(/* webpackChunkName: "thankyou" */'@/views/ThankYouPage.vue')
// const SiteMapGen = () => import(/* webpackChunkName: "sitemapgen" */'@/views/SiteMapGenerator.vue')

Vue.use(Router)

function getCategories (categories) {
  return Object.entries(categories).map(([key, value]) => {
    if (value.categories) return [key.replace(',', '|'), getCategories(value)]
    return key.replace(',', '|')
  }).join(',').split(',').filter(e => e !== '')
}
let categories = getCategories(menuCategories).map(v => v.replace('|', ','))

export const routes = [
  // {
  //   path: '/generateSiteMap',
  //   component: SiteMapGen
  // },
  {
    path: '/login/:code/:otp_id',
    name: 'login-code',
    component: LoginCode
  },
  {
    path: '/',
    name: 'HomePage',
    // props: {
    //   collectionName: 'kiuki_homepage'
    // },
    meta: {
      showSubMenu: true,
      searchChangeRoute: true
    },
    component: HomePage
  },
  {
    path: '/recipe/:slug',
    name: 'recipePage',
    meta: {
      searchChangeRoute: true
    },
    component: RecipePage
  },
  {
    path: '/:language?',
    name: 'home',
    component: ViewContainer,
    children: [
      {
        path: '/cart',
        name: 'cart',
        // props: {
        //   itemType: '',
        //   collectionName: ''
        // },
        // meta: { showSubMenu: true },
        component: UserCart
      },
      {
        path: '/checkout',
        name: 'checkout',
        // props: {
        //   itemType: '',
        //   collectionName: ''
        // },
        // meta: { showSubMenu: true },
        component: Checkout
      },
      {
        path: '/thank-you',
        name: 'thankyou',
        // props: {
        //   itemType: '',
        //   collectionName: ''
        // },
        // meta: { showSubMenu: true },
        component: ThankYou
      },
      {
        path: '/search',
        name: 'search',
        props: {
          itemType: '',
          collectionName: ''
        },
        // meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'tag/:tag',
        name: 'tag',
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'restaurants',
        name: 'restaurants',
        props: { itemType: 'restaurant' },
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'online-shopping',
        name: 'online shopping',
        props: { itemType: 'packagedfood' },
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'cafes',
        name: 'cafes',
        props: { itemType: 'cafe' },
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'hotels',
        name: 'hotels',
        props: { itemType: 'hotel' },
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: 'holidays',
        name: 'holidays',
        template: '<div/>'
      },
      {
        path: 'experiences',
        name: 'experiences',
        component: ItemList,
        props: {
          itemType: 'offer'
        },
        meta: { showSubMenu: true }
      },
      {
        path: 'eat-experiences',
        name: 'eat experiences',
        component: ItemList,
        props: {
          itemType: 'offer',
          collectionName: 'veganbaseeatexperiences'
        },
        meta: { showSubMenu: true }
      },
      {
        path: 'travel-experiences',
        name: 'travel experiences',
        component: ItemList,
        props: {
          itemType: 'offer',
          collectionName: 'veganbasetravelexperiences'
        },
        meta: { showSubMenu: true }
      },
      {
        path: 'mag',
        name: 'Mag',
        props: {
          // collectionName: 'vk_magazine',
          itemType: 'article'
        },
        component: ItemList
      },
      {
        path: 'press-releases',
        name: 'Press releases',
        props: {
          itemType: 'article',
          collectionName: 'vbpressreleases'
        },
        component: ItemList
      },
      {
        path: 'recipes/:slug',
        redirect: 'recipe/:slug'
      },
      {
        path: 'recipes',
        name: 'recipes',
        props: {
          itemType: 'recipe'
        },
        component: ItemList,
        meta: { showSubMenu: true }
      },
      {
        path: 'recipe/:slug',
        name: 'recipe',
        props: {
          itemType: 'recipe',
          searchChangeRoute: true
        },
        component: ItemArticle
      },
      {
        path: 'mag/:slug',
        name: 'article',
        props: {
          itemType: 'article',
          searchChangeRoute: true
        },
        component: ItemArticle
      },
      {
        path: 'app',
        name: 'app',
        component: MobileAppAdvert,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'add-listing',
        name: 'add listing',
        component: AddListingPage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'about',
        name: 'about',
        component: AboutPage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'about-us',
        redirect: 'about'
      },
      {
        path: 'delivery',
        name: 'delivery',
        component: ShippingPage,
        meta: { searchChangeRoute: true }
      },
      // {
      //   path: 'platform',
      //   name: 'platform',
      //   component: PlatformUpdates,
      //   meta: { searchChangeRoute: true }
      // },
      {
        path: 'friends',
        name: 'friends',
        component: AffiliatePage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'merchants',
        name: 'merchants',
        component: MerchantPage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'contact',
        name: 'contact',
        component: ContactPage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: PrivacyPage,
        meta: { searchChangeRoute: true }
      },
      {
        path: 'blog',
        name: 'blog',
        template: '<div/>',
        meta: { searchChangeRoute: true }
      },
      {
        path: `/:categoryName(${categories.join('|')})`,
        name: 'categoryPage',
        meta: { showSubMenu: true },
        component: ItemList
      },
      {
        path: `/shop/stores-near-me`,
        component: ItemList,
        props: {
          itemType: 'shop'
        }
      },
      {
        path: `/shop/:collection`,
        component: ItemList,
        props: (route) => ({
          itemType: '',
          collectionName: route.params.collection
        }),
        meta: { showSubMenu: true }
      },
      {
        path: `/o/:slug`,
        component: OrgPage,
        name: 'orgPage',
        meta: { searchChangeRoute: true }
      },
      {
        path: `/purchase/:purchaseId`,
        redirect: to => {
          return {
            path: '/',
            query: { purchaseId: to.params.purchaseId }
          }
        }
      },
      {
        path: `/user-contacts`,
        redirect: to => {
          return {
            path: '/',
            query: { openContacts: true }
          }
        }
      },
      {
        path: '/best-vegan-cheese',
        component: ItemList,
        name: 'category',
        props: () => ({
          itemType: 'packagedfood',
          collectionName: 'vk_cheese',
          propsCollectionTitle: 'Best Vegan Cheese'
          // showDescription: false
        }),
        meta: { showSubMenu: true }
      },
      {
        path: '/best-vegan-milk',
        component: ItemList,
        name: 'category',
        props: () => ({
          itemType: 'packagedfood',
          collectionName: 'vk_plantbasedmilk',
          propsCollectionTitle: 'Best Plant-Based Milk'
          // showDescription: false
        }),
        meta: { showSubMenu: true }
      },
      // {
      //   path: '/best-vegan-meat',
      //   component: ItemList,
      //   name: 'category',
      //   props: () => ({
      //     itemType: '',
      //     collectionName: 'vk_bestveganmeat',
      //     propsCollectionTitle: 'Best Vegan Meat'
      //     showDescription: false
      //   }),
      //   meta: { showSubMenu: true }
      // },
      {
        path: '/terms-and-conditions',
        component: TermsConditions
      },
      {
        path: '/c/:slug',
        component: ItemList,
        name: 'category',
        props: () => ({
          itemType: '',
          collectionName: ''
        }),
        meta: { showSubMenu: true }
      },
      {
        path: '/:slug(.{8,})',
        name: 'item',
        component: ItemPage,
        meta: {
          searchChangeRoute: true
        },
        beforeEnter: (to, from, next) => {
          if (from.matched.length > 0) {
            to.meta.canGoBack = true
          } else {
            to.meta.canGoBack = false
          }
          next()
        }
      },
      // {
      //   path: '',
      //   name: 'everything',
      //   props: { itemType: 'homepage' },
      //   component: ItemList,
      //   beforeEnter: (to, from, next) => {
      //     if (to.path !== '/' && to.path !== '/' + to.params.language + '/') {
      //       next({ name: '404' })
      //     }
      //     next()
      //   }
      // },
      {
        path: '*',
        // path: '/not-found',
        name: '404',
        props: { itemType: '404', itemTypeLetter: '' },
        component: p404
      }
    ]
  }
]

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0, behavior: 'smooth' })
      }, 250)
    })
  },
  routes
})

<template>
  <div id="app">
    <modal-box
      ref="confirmPaymentModal"
      title="Payment Confirmation"
      :iframeUrl="$store.state.iframePaymentUrl"
      type="no-footer"
    />
    <gallery :images="$store.state.galleryImages" :index="$store.state.openImageIndex" @close="$store.commit('setOpenImageIndex', null)"></gallery>
    <!-- <holiday-top-banner /> -->
    <top-banner
      @openProfileSettings="openProfileSettings"
    />
    <top-links />
    <top-menu
      v-if="!$route.path.includes('/account')"
      :userLoggedIn="userLoggedIn"
      :userData="userData"
      :showCategoryDropdown="true"
      @hamburgerClicked="hamburgerClicked"
      @openCart="openCart"
      @openMagicSidebar="openSidebar"
      :notificationsQuantity="$refs.magicSidebar ? $refs.magicSidebar.notifications ? $refs.magicSidebar.notifications.length : 0 : 0"
      :ordersQuantity="$refs.magicSidebar ? $refs.magicSidebar.itemsOnCart : 0"
      :sideMenuHidden="$store.state.sideMenuHidden">
    </top-menu>
    <top-sub-menu v-if="$route.meta.showSubMenu" />
    <MagicSideBar
      style="z-index: 55"
      :hidden="!magicSideBarOpen"
      @tokenChanged="handleCSRFtokenChanged"
      :displayLoginIssuesWarning="false"
      :staging="isDev"
      :apiUrl="apiUrl"
      :stripeKey="stripeKey"
      :enableRewardful="true"
      :imgixDomain="imgixDomain"
      :minPriceToOrder="50"
      :disableSubscriptionsStep="true"
      :disableDeliveryStep="false"
      :enableDeleteAccount="true"
      :retryPaymentInterval="5"
      :enableLoyaltyPoints="true"
      :unavailableDeliveryDates="[
        '2024/03/29',
        '2024/03/30',
        '2024/03/31',
        '2024/04/01',
        '2024/04/02',
      ]"
      :useExternalCheckout="true"
      deliveryMessageBackground="#3BAA5B"
      deliveryMessageTextColor="#FFFFFF"
      ref="magicSidebar"
      @openSignInPage="openSignInPage"
      @signedIn="handleSignedIn"
      @signedOut="handleSignedOut"
      @authPurchase="handlePurchase3d"
      v-click-outside="(e) => { (!e.target.className.includes('userCart') && windowWidth > 520) ? magicSideBarOpen = false : null }"
      @close="() => { magicSideBarOpen = false }">
    </MagicSideBar>
    <SideMenu
      style="z-index: 50"
      :hidden="$store.state.sideMenuHidden"
      @requestCloseSideMenu="handleSideMenuVisibility(true)"
      :categories="menuCategories"
      :logo-url="require('./assets/logo.svg')"
      :social-urls="{
        facebook: 'https://www.facebook.com/thevegankind',
        instagram: 'https://www.instagram.com/thevegankind/',
        twitter: 'https://twitter.com/thevegankind',
        linkedin: 'https://www.linkedin.com/company/thevegankind/'
      }"
      :about-path="'/about'"
      :contact-path="'/contact'"
      :privacy-path="'/privacy'"
      :listing-path="'/add-listing'"
      :delivery-path="'/delivery'"
      :affiliate-path="'/friends'"
      :external-link="'https://wholefoodearth.com'"
      external-link-text="Whole Food Earth ®"
      :terms-and-conditions-path="'/terms-and-conditions'"
      :app-path="'/app'"
      :listingNewPage="false"
      :clearQueryOnSelect="true"
      @handleClearQuery="handleClearQuery"
      v-click-outside="() => handleSideMenuVisibility(true)"
      :preSelectedCategory="$store.state.defaultSideMenuCategory"
    />
    <ais-instant-search
      :search-client="algoliaClient"
      :index-name="algoliaIndex"
    >
      <snackbar
        ref="snackbar"
        base-size="100px"
        :hold-time="5000"
        position="bottom-center"
      />
      <div class="routerView" :class="{'routerView--full' : $store.state.sideMenuHidden}">
        <transition :name="!$route.path.includes('/account') ? 'slide-fade' : null">
          <router-view
            v-if="!notFound"
            :key="$route.path"
            @makePurchase="makePurchase"
            @addItemToCart="addItemToCart"
            @openMagicSidebar="() => { magicSideBarOpen = true }"
            @openPurchases="openPurchases"
            @openUserContacts="openUserContacts"
            @openSnackbar="openSnackbar"
            @openSignInPage="openSignInPage"
            @removeOrder="removeOrder"
          />
          <p404 v-else />
        </transition>
      </div>
    </ais-instant-search>
  </div>
</template>
<script>
// import HolidayTopBanner from '@/components/HolidayTopBanner.vue'
import ClickOutside from 'vue-click-outside'
import VueGallery from 'vue-gallery'
import httpClient from '@/services/httpClient'
import { algoliaClient } from '@kiukicom/listing'
import menuCategories from '@/menuCategories.js'
// import siteConstants from '@/const.js'
import Snackbar from 'vuejs-snackbar'
import TopMenu from '@/components/TopMenu.vue'
import TopSubMenu from '@/components/TopSubMenu.vue'
// const TopBanner = () => import('@/components/TopBanner.vue')
import '@stripe/stripe-js'

export default {
  name: 'App',
  metaInfo: {
    title: 'Vegan Supermarket',
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' }
    ]
  },
  directives: {
    ClickOutside
  },
  components: {
    TopMenu,
    TopSubMenu,
    TopLinks: () => import('@/components/TopLinks.vue'),
    TopBanner: () => import('@/components/TopBanner.vue'),
    Snackbar,
    // HolidayTopBanner,
    p404: () => import('@/views/404.vue'),
    'gallery': VueGallery
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development'
    },
    pageNotFound () {
      return this.$store.state.notFound
    }
  },
  data: function () {
    return {
      // siteConstants,
      magicSideBarOpen: false,
      sideUserMenuHidden: true,
      userData: {},
      userLoggedIn: false,
      sideUserMenuAction: '',
      apiUrl: process.env.VUE_APP_API_URL,
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      imgixDomain: process.env.VUE_APP_IMGIX_DOMAIN,
      algoliaIndex: process.env.VUE_APP_ALGOLIA_INDEX,
      algoliaClient,
      menuCategories,
      menus: [],
      notFound: false,
      windowWidth: window.innerWidth
    }
  },
  created () {
    this.getItemMenus()
    const subNavbarMenus = JSON.parse(localStorage.getItem('websiteTopSubMenuItems'))
    if (subNavbarMenus !== undefined && subNavbarMenus !== null && subNavbarMenus.length > 0) {
      this.$store.commit('setServerFetchedCategories', subNavbarMenus)
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // this.$refs.confirmPaymentModal.show = true
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    pageNotFound: {
      handler () {
        this.notFound = true
      },
      deep: true
    },
    $route (to, from) {
      this.notFound = false
    },
    menus: {
      handler () {
        if (this.menus.length === 0) return
        // Begin Section for Left Menu Sidebar
        const categories = {}
        this.menus.forEach(item => {
          this.convertMenuItemsToSideMenu(item, categories)
        })
        this.menuCategories.Shop = categories
        // End Section for Left Menu Sidebar

        // Start Section for TopSubMenu
        const topSubMenuMenus = this.menus.map(item => {
          return this.convertMenuItemsTopSubMenu(item, {})
        })

        this.$store.commit('setServerFetchedCategories', topSubMenuMenus)
        localStorage.setItem('websiteTopSubMenuItems', JSON.stringify(topSubMenuMenus))
        // End Section for TopSubMenu
      }
    }
  },
  methods: {
    removeOrder ($event) {
      this.$refs.magicSidebar.removeOrder($event)
    },
    openSignInPage () {
      this.$refs.magicSidebar.handleOpenSigninPage(true)
      this.magicSideBarOpen = true
      // Call magicSidebar function to open login
    },
    makePurchase (purchaseData) {
      this.$refs.magicSidebar.handleMakePurchase(purchaseData)
    },
    convertMenuItemsTopSubMenu (menuItem, parent) {
      const item = {
        name: menuItem.name,
        slug: menuItem.slug,
        collection: menuItem.collection !== undefined ? menuItem.collection.name : ''
      }
      if (menuItem.child_menus !== undefined) {
        // Has Child menus
        item.subCategories = menuItem.child_menus.map((child) => {
          return this.convertMenuItemsTopSubMenu(child, item)
        })
      }
      return item
    },
    convertMenuItemsToSideMenu (menuItem, parentObject) {
      const CATEGORY_PREFIX = '/c/'
      if (menuItem.child_menus !== undefined) {
        // Has Child Menus
        if (menuItem.collection !== undefined) {
          // Has Collection associated
          parentObject[menuItem.name] = { 'All': CATEGORY_PREFIX + menuItem.slug }
        } else {
          // Does not have a collection associated
          parentObject[menuItem.name] = {}
        }
        // Iterate Children
        menuItem.child_menus.forEach(child => {
          this.convertMenuItemsToSideMenu(child, parentObject[menuItem.name])
        })
      } else {
        // Menu Item does not have any children, so it can be added straight to the object
        parentObject[menuItem.name] = CATEGORY_PREFIX + menuItem.slug
      }
    },
    getItemMenus () {
      httpClient
        .get(`item-menus`)
        .then(response => {
          this.menus = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    openSnackbar ({ message, type }) {
      if (type === 'info') this.$refs.snackbar.info(message)
      else if (type === 'error') this.$refs.snackbar.error(message)
      else if (type === 'warn') this.$refs.snackbar.warn(message)
      else this.$refs.snackbar.open(message)
    },
    handleClearQuery () {
      this.$store.commit('setFilteringQuery', '')
      this.$store.commit('updateSearchValue', '')
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    handleCSRFtokenChanged (newToken) {
      httpClient.defaults.headers.common['X-CSRF-TOKEN'] = newToken
    },
    hamburgerClicked () {
      document.cookie = 'sideMenuHidden=' + this.$store.state.sideMenuHidden
      this.$store.commit('setSideMenuVisibility', !this.$store.state.sideMenuHidden)
    },
    handleSideMenuVisibility (newState = undefined) {
      if (newState !== undefined) {
        this.$store.commit('setSideMenuVisibility', newState)
      }
    },
    handleSignedIn (userData) {
      this.userLoggedIn = true
      this.userData = userData
      this.$store.commit('setUserData', userData)
    },
    handleSignedOut () {
      this.userLoggedIn = false
      this.userData = {}
      this.$store.commit('setUserData', {})
    },
    handlePurchase3d (iframeUrl) {
      this.$store.commit('setIframePaymentUrl', iframeUrl)
      this.$refs.confirmPaymentModal.show = true
    },
    openSideUserMenu (actionName) {
      this.sideUserMenuHidden = false
      this.sideUserMenuAction = actionName
    },
    closeSideUserMenu () {
      this.sideUserMenuHidden = true
    },
    openCart () {
      this.magicSideBarOpen = true
      this.$refs.magicSidebar.openCart()
    },
    openPurchases (purchaseId) {
      this.magicSideBarOpen = true
      this.$refs.magicSidebar.openPurchases(purchaseId)
    },
    openUserContacts () {
      this.magicSideBarOpen = true
      this.$refs.magicSidebar.openUserContacts()
      // Clear Query
      this.$router.replace({ query: {} })
    },
    openProfileSettings () {
      this.magicSideBarOpen = true
      this.$refs.magicSidebar.openProfileSettings()
    },
    openSidebar () {
      this.magicSideBarOpen = true
      if (!this.userLoggedIn) {
        this.$refs.magicSidebar.openPage = 'signIn'
      } else {
        // this.$refs.magicSidebar.$refs.userPage.openedPage = 'profile'
      }
    },
    addItemToCart (itemData) {
      this.$refs.magicSidebar.addOrder(itemData)
      try {
        this.gtmAddToCart(itemData)
      } catch (error) {
        // Empty
      }
      const currentUrl = this.$route.name
      if (currentUrl !== 'checkout' && currentUrl !== 'cart') {
        setTimeout(this.openCart, 10)
      }
    },
    getCookie (name) {
      name = name + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    gtmAddToCart (itemData) {
      // console.log({
      //   // currency: itemData.currency, // ? currency should be in "3-letter ISO 4217" format
      //   // Price does not have decimal point on itemData object, therefore
      //   // an item with a price of 10£ will have itemData.price = 1000
      //   value: itemData.item_type === 'packagedfood' ? itemData.offering.price / 100 : itemData.price / 100,
      //   currency: itemData.item_type === 'packagedfood' ? itemData.offering.currency : itemData.currency,
      //   items: [{
      //     item_id: itemData.item_type === 'packagedfood' ? itemData.id : itemData.id, // original Item Id (packagedfood/whatever)
      //     item_name: itemData.name,
      //     item_variant: itemData.offering.id,
      //     item_brand: itemData.owner ? itemData.owner.name : 'Seller', // Maybe add in the future as the owner org
      //     price: itemData.offering.price / 100,
      //     quantity: itemData.offering.quantity
      //   }]
      // })
      window.dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          // currency: itemData.currency, // ? currency should be in "3-letter ISO 4217" format
          // Price does not have decimal point on itemData object, therefore
          // an item with a price of 10£ will have itemData.price = 1000
          value: itemData.item_type === 'packagedfood' ? itemData.offering.price / 100 : itemData.price / 100,
          currency: itemData.item_type === 'packagedfood' ? itemData.offering.currency : itemData.currency,
          items: [{
            item_id: itemData.item_type === 'packagedfood' ? itemData.id : itemData.id, // original Item Id (packagedfood/whatever)
            item_name: `${itemData.name}${itemData.owner ? ` - ${itemData.owner.name}` : ''}`,
            item_variant: itemData.offering.id,
            item_brand: itemData.owner ? itemData.owner.name : 'Seller', // Maybe add in the future as the owner org
            price: itemData.offering.price / 100,
            quantity: itemData.offering.quantity
          }]
        }
      })
    }
  }
}
</script>
<style lang="stylus">
@font-face
  font-family "Font Awesome 5 Brands"
  font-style normal
  font-weight normal
  src url(assets/webfonts/fa-brands-400.eot)
  src: url(assets/webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),
    url(assets/webfonts/fa-brands-400.woff2) format("woff2"),
    url(assets/webfonts/fa-brands-400.woff) format("woff"),
    url(assets/webfonts/fa-brands-400.ttf) format("truetype"),
    url(assets/webfonts/fa-brands-400.svg#fontawesome) format("svg")
@font-face
  font-family "Font Awesome 5 Pro"
  font-style normal
  font-weight 300
  src url(assets/webfonts/fa-light-300.eot)
  src url(assets/webfonts/fa-light-300.eot?#iefix) format("embedded-opentype"), url(assets/webfonts/fa-light-300.woff2) format("woff2"), url(assets/webfonts/fa-light-300.woff) format("woff"), url(assets/webfonts/fa-light-300.ttf) format("truetype"), url(assets/webfonts/fa-light-300.svg#fontawesome) format("svg")
@font-face
  font-family "Font Awesome 5 Pro Solid"
  font-style normal
  font-weight 800
  src url(assets/webfonts/fa-solid.otf)
a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline : 0;
}
.userPage__buttons button {
  font-weight: 500 !important;
}
*:focus
  outline: none
.fal
  font-family "Font Awesome 5 Pro"
  font-weight 300
.fas
  font-family "Font Awesome 5 Pro Solid"
  font-weight 800
.fab
  font-family "Font Awesome 5 Brands"

.v-lazy-image {
  filter: blur(6px);
  opacity: 0.2;
  transition: 0.2s;
}
.v-lazy-image-loaded {
  filter: blur(0);
  opacity: 1;
}

html, body
  min-height 100%
  // overflow hidden
  margin 0
p
  font-size 14px
  color black
  font-weight 400
.gray-text
  color #5d5d5d
  p
    color #5d5d5d
.lightgray-text
  color #b3b3b3
a
  text-decoration none !important
  color #3B8EDE
  cursor pointer
  &[disabled='disabled']
    color #ccc
nav a
  color black
h1
  color black
  font-size 25px
  font-weight 400 !important
  // font-family Baskerville, sans-serif
h2
  color black
  font-size 22px
  font-weight 400 !important
  // font-family Baskerville, sans-serif
  margin 0
h3
  color black
  font-size 20px
  font-weight 400 !important
  // font-family Baskerville, sans-serif
#app
  font-family 'Open Sans', Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  font-size 14px
  font-weight 400
  -moz-osx-font-smoothing grayscale
  text-align center
  height 100%
  color black
  // background #fafafa
  // overflow hidden
.routerView
  width 100%
  max-width 100vw
  // height 100%
  overflow-x hidden
  box-sizing border-box
  transition-duration .4s
  transition-timing-function cubic-bezier(0.645, 0.045, 0.355, 1)
  &--full
    padding-left 0
.slide-fade-enter-active
  transition all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0)
  transition-delay .4s
.slide-fade-leave-active
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.slide-fade-enter, .slide-fade-leave-to
  transform translateX(10px)
  opacity 0
.slide-fade-enter-to
  transform translateX(0)
  opacity 1
.popup-fade-enter-active
  transition all .07s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.popup-fade-leave-active
  transition: all .07s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.popup-fade-enter, .popup-fade-leave-to
  transform translateY(-12px)
  opacity 0
.popup-fade-enter-to
  transform translateX(0)
  opacity 1
.pac-container
  min-width 320px
  margin-top 5px
  margin-left -25px
  padding 8px 8px 8px 0
  box-shadow none
  box-shadow inset 0 6px 6px #eee
  border-top none
  border-radius 0 0 5px 5px
  .pac-item
    padding 4px 13px
    border none
  .pac-icon
    background-image url('assets/map-marker-light.svg')
    background-position center
    background-size 11px
    background-repeat no-repeat

@media screen and (max-width: 1000px) // Relative to SideMenu!
  .routerView
    width 100%

@media screen and (max-width: 768px)
  .routerView
    padding-left 0

@font-face {
  font-family: 'Baskerville';
  src: url('assets/webfonts/Baskerville-Regular.woff2') format('woff2'),
      url('assets/webfonts/Baskerville-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

</style>
